/* --------------------------------------------- FONTS --------------------------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Barrio&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barrio&family=Josefin+Sans&display=swap');

/* --------------------------------------------- GLOBAL STYLES --------------------------------------------- */

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Josefin Sans', sans-serif;
  font-style: 'normal';
}


/* Slider */
#range-slider {
  background: #E0E0E0;
  height: 12px;
  border-radius: 40px;
}

#range-slider .range-slider__thumb[data-lower] {
  width: 0;
  border: 0;
}

#range-slider .range-slider__range {
  background: #DF552A;
  transition: height 0.3s;
  border-radius: 40px;
}

#range-slider .range-slider__thumb {
  background: #DF552A;
  transition: transform 0.3s;
  height: 18px;
  width: 18px;
  border: 2px solid #FFFFFF;
}

#range-slider .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#range-slider .range-slider__range[data-active] {
  height: 12px;
}
